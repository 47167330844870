<template>
  <div>
    <!-- main -->
    <el-row class="goods-form">
      <el-col :span="24">
        <el-form :model="ruleForm" :rules="rule" ref="ruleForm" label-width="150px">
          <!-- *Trade name 商品名 -->
          <el-form-item prop="trade" :label="$t('productManagement.trandName')">
            <el-input
              type="text"
              v-model="ruleForm.trade"
              maxlength="200"
              :placeholder="$t('productManagement.trandNames')"
            />
          </el-form-item>

          <!-- *Commodity classification 商品分类 -->
          <el-form-item prop="category" :label="$t('productManagement.category')" class="category">
            <el-select
              type="text"
              class="select fl"
              v-model="firstCategory"
              :placeholder="$t('productManagement.categoryRequired')"
              @change="changeFirstCategory()"
            >
              <el-option
                v-for="item in categories"
                :key="item._id"
                :label="$t('category.' + item.categoryName)"
                :value="item._id"
              ></el-option>
            </el-select>

            <el-select
              class="select fl"
              v-model="secondCategory"
              :placeholder="$t('productManagement.categoryRequired')"
              @change="changeSecondCategory()"
            >
              <el-option
                v-for="item in secondCategoryData"
                :key="item._id"
                :label="$t('category.' + item.categoryName)"
                :value="item._id"
              ></el-option>
            </el-select>

            <el-select
              class="select fl"
              v-model="thirdCategory"
              :placeholder="$t('productManagement.categoryRequired')"
              @change="chagneThirdCategory()"
            >
              <el-option
                v-for="item in thirdCategoryData"
                :key="item._id"
                :label="$t('category.' + item.categoryName)"
                :value="item._id"
              ></el-option>
            </el-select>
            <div class="clear"></div>
          </el-form-item>
          <!-- 属性 -->
          <el-form-item
            prop="attribute"
            :label="$t('productManagement.attribute')"
            class="attribute"
          >
            <div class="attribute">
              <p class="p">{{ $t("productManagement.parameter") }}</p>
              <div class="select1" v-for="(item, index) in forms.attribute" :key="index">
                <el-input
                  v-model="item.key"
                  :placeholder="
                    $t('productManagement.attributeColorplaceholder')
                  "
                  class="select4"
                  @change="enterAttributes(index)"
                ></el-input>
                <el-input
                  v-model="item.value"
                  :placeholder="$t('productManagement.attributeColorRemark')"
                  class="input2"
                  @change="enterAttributes(index)"
                ></el-input>
                <span
                  class="attr_operate"
                  :class="item.displayShow"
                  @click="attrOperates(1, index)"
                >+</span>
                <span class="attr_operate" @click="attrOperates(0, index)">-</span>
              </div>
            </div>
          </el-form-item>
          <!-- *Select brand 精选品牌 -->
          <el-form-item prop="brand" :label="$t('productManagement.brand')">
            <el-row>
              <el-col :span="6">
                <el-select
                  type="text"
                  v-model="ruleForm.brand"
                  clearable
                  :placeholder="$t('productManagement.brandRequired')"
                >
                  <el-option
                    v-for="item in brands"
                    :key="item._id"
                    :label="item.brandName"
                    :value="item._id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-image
                  v-if="ruleForm.brand && brands.some(v => v._id === ruleForm.brand)"
                  style="width: 45px; height: 45px; margin-left: 10px;"
                  :src="brands.find(v => v._id === ruleForm.brand)['img']"
                  :preview-src-list="[brands.find(v => v._id === ruleForm.brand)['img']]"
                ></el-image>
              </el-col>
              <el-col :span="4">
                <span class="add_brand" @click="addBrand">
                  {{
                  $t("productManagement.addBrand")
                  }}
                </span>
              </el-col>
            </el-row>
          </el-form-item>

          <!-- *Place of shipment 装运地 -->
          <el-form-item prop="shipment" :label="$t('productManagement.placeOfShipment')">
            <el-select v-model="ruleForm.shipment" filterable>
              <template v-if="ruleForm.shipment" v-slot:prefix>
                <div class="flag" :class="flagClassObject"></div>
              </template>
              <el-option-group v-for="group in country" :key="group.label" :label="group.label">
                <el-option
                  v-for="item in group.options"
                  :key="item.name"
                  :label="$t('categorys.' + item.value)"
                  :value="item.value"
                >
                  <div class="flag" :class="`flag-${item.iso_code}`"></div>
                  {{ $t("categorys." + item.value) }}
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>

          <!-- Minimum number of ordered pieces 订购件的最小数量 -->
          <el-form-item prop="minimum" :label="$t('productManagement.minimumNumber')">
            <el-input type="number" v-model.number="ruleForm.minimum" />
          </el-form-item>

          <!-- 每一个箱的数量 -->
          <el-form-item
            v-if="ruleForm.minimum"
            prop="incMinimum"
            :label="$t('productManagement.incMinimum')"
          >
            <el-input type="number" v-model.number="ruleForm.incMinimum" />
          </el-form-item>
          <!-- 产品体积 -->
          <el-form-item prop="volume" :label="$t('productManagement.volume')">
            <!-- <el-input class="volumes" type="text" v-model="ruleForm.volume" /> -->
            <input
              class="volumes d el-input__inner col-input"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              v-model="ruleForm.volumeL"
              @blur="volumeLs(ruleForm.volumeL)"
              :placeholder="$t('productManagement.l')"
            />
            <span class="x">*</span>
            <input
              class="volumes w el-input__inner col-input"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              v-model="ruleForm.volumeW"
              @blur="volumeWs(ruleForm.volumeW)"
              :placeholder="$t('productManagement.w')"
            />
            <span class="x">*</span>
            <input
              class="volumes h el-input__inner col-input"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              v-model="ruleForm.volumeH"
              @blur="volumeHs(ruleForm.volumeH)"
              :placeholder="$t('productManagement.h')"
            />
            <el-select
              v-model="ruleForm.volume"
              :placeholder="$t('resident.pleaseSelect')"
              class="volume"
            >
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 产品重量 -->
          <el-row>
            <el-col :span="9">
              <el-form-item prop="weight.size" :label="$t('productManagement.weight')">
                <el-input
                  class="weights"
                  type="text"
                  v-model="ruleForm.weight.size"
                  oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item prop="weight.unit" label-width="0">
                <el-select
                  v-model="ruleForm.weight.unit"
                  :placeholder="$t('resident.pleaseSelect')"
                  class="weight"
                  disable
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 标签 -->
          <el-form-item :label="$t('productManagement.tags')">
            <vue-tags-input
              v-model="tag"
              :tags="
                ruleForm.tags.map((v) => {
                  return {
                    text: v,
                  };
                })
              "
              class="tags"
              @tags-changed="
                (newTags) =>
                  (ruleForm.tags = newTags.map((v) => {
                    return v.text;
                  }))
              "
              :placeholder="$t('productManagement.add')"
            />
          </el-form-item>

          <!-- packaging 打包 -->
          <el-form-item
            class="description"
            prop="packaging"
            :label="$t('productManagement.packaging')"
          >
            <el-input
              type="number"
              v-model.number="ruleForm.packaging"
              :placeholder="$t('productManagement.packagingNum')"
            />
          </el-form-item>
          <!-- graphic 形象的 -->
          <el-form-item class="description" prop="graphic" :label="$t('productManagement.graphic')">
            <el-input v-model="ruleForm.graphic" :placeholder="$t('productManagement.graphicNum')" />
          </el-form-item>
          <!-- logo -->
          <el-form-item class="description" prop="logo" :label="$t('productManagement.logo')">
            <el-input v-model="ruleForm.logo" :placeholder="$t('productManagement.logoNum')" />
          </el-form-item>

          <!-- Description 描述 -->
          <el-form-item
            class="description"
            prop="description"
            :label="$t('productManagement.description')"
          >
            <el-input
              v-model="ruleForm.description"
              type="textarea"
              :placeholder="$t('productManagement.descriptionss')"
              :autosize="{ minRows: 2, maxRows: 4 }"
            />
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <!-- 规格 -->
    <el-card class="card">
      <el-form label-width="150px">
        <!-- 起始批量 -->
        <el-row>
          <el-col :span="8">{{ $t("productManagement.initial") }}</el-col>
          <el-col :span="7">{{ $t("productManagement.deliveryTime") }}</el-col>
        </el-row>
        <el-row class="mt">
          <el-form-item prop="attribute">
            <div class="attribute mt colour">
              <div class="select1" v-for="(item, index) in form1.attribute" :key="index">
                <el-col :span="7" style="margin-top: 0px">
                  <el-input
                    v-model="item.small"
                    placeholder="1"
                    class="small fl"
                    :readonly="index === 0"
                    @change="enterAttribute2(index)"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  ></el-input>

                  <div class="fl line"></div>

                  <el-input
                    v-model="item.large"
                    placeholder
                    class="large fl"
                    @change="enterAttribute2(index)"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                    onafterpaste="this.value=this.value.replace(/\D/g,'')"
                  ></el-input>

                  <!-- <i class="el-icon-error delete"></i> -->
                </el-col>

                <el-input
                  v-model="item.delivery"
                  :placeholder="$t('productManagement.delivery')"
                  class="delivery"
                  type="number"
                  min="0"
                  @change="enterAttribute2(index)"
                  onkeyup="this.value=this.value.replace(/\D/g,'')"
                  onafterpaste="this.value=this.value.replace(/\D/g,'')"
                >
                  <template v-slot:suffix>
                    {{
                    $t("productManagement.day")
                    }}
                  </template>
                </el-input>
                <span
                  class="attr_operate"
                  :class="item.displayShow"
                  @click="attrOperate2(1, index)"
                >+</span>
                <span class="attr_operate" @click="attrOperate2(0, index)">-</span>
              </div>
            </div>
          </el-form-item>
        </el-row>

        <el-divider></el-divider>

        <!-- colour 颜色 -->
        <h4>{{ $t("productManagement.colour") }}</h4>
        <el-form-item prop="attribute">
          <div class="attribute mt colour">
            <div class="select1" v-for="(item, index) in form.attribute" :key="index">
              <el-row type="flex" justify="start" align="middle">
                <el-col :span="3">
                  <template v-if="item.img">
                    <el-button
                      icon="el-icon-delete"
                      style="font-size: 24px; padding: 3px 8px"
                      @click="handleDeleteColorImage($event, index)"
                    >
                      <el-image
                        :src="item.img + '?x-oss-process=style/w64'"
                        style="width: 45px; height: 45px"
                      ></el-image>
                    </el-button>
                  </template>
                  <MaterialSelector
                    v-else
                    :count="1"
                    type="image"
                    @receiver="handleSelectionResult($event, index)"
                  >
                    <template v-slot:custom-open-button>
                      <el-button icon="el-icon-plus">
                        <i class="el-icon-picture"></i>
                      </el-button>
                    </template>
                  </MaterialSelector>
                </el-col>
                <el-col :span="5">
                  <el-input
                    v-model="item.color"
                    :placeholder="$t('productManagement.color')"
                    class="w-auto"
                    @change="enterAttribute(index, 0)"
                  ></el-input>
                </el-col>
                <el-col :span="5">
                  <el-input
                    v-model="item.colorRemark"
                    :placeholder="$t('productManagement.remark')"
                    class="w-auto"
                    @change="enterAttribute(index, 1)"
                  ></el-input>
                </el-col>
                <el-col :span="2">
                  <span
                    class="attr_operate"
                    :class="item.displayShow"
                    @click="attrOperate(1, index)"
                  >+</span>
                </el-col>
                <el-col :span="1">
                  <span class="attr_operate" @click="attrOperate(0, index)">-</span>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form-item>
        <el-divider></el-divider>

        <!-- size -->
        <div class="size mt">
          <span>{{ $t("productManagement.size") }}</span>
          <el-input
            v-model="size"
            :placeholder="$t('productManagement.sizeInput')"
            maxlength="64"
            class="input1"
          ></el-input>
          <el-button class="button" @click="add">
            <i class="el-icon-plus"></i>
            {{ $t("common.add") }}
          </el-button>
        </div>
        <el-row>
          <el-col :span="3" class="mt1 checkboxs fl" v-for="(item, i) in sizeDataLists" :key="i">
            <span class="edit">{{ item }}</span>

            <!-- 编辑 -->
            <i class="el-icon-edit" @click="edit(i)"></i>

            <!-- 删除 -->
            <i class="el-icon-delete" @click="remove(i)"></i>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <!-- 编辑弹框 -->
    <el-dialog :visible="myVisible" width="30%" @close="close">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item :label="$t('productManagement.size')">
          <el-input v-model="form.sizeEditValue" class="editSizeValue"></el-input>
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="close">{{ $t("common.cancel") }}</el-button>
          <el-button type="primary" @click="doneForm">
            {{
            $t("common.done")
            }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- specifications 商品销售规格-->
    <div class="specifications">
      <h2>{{ $t("productManagement.spec") }}</h2>
      <el-card class="card">
        <!-- title -->
        <el-row class="title">
          <el-col :span="5">{{ $t("productManagement.colour") }}</el-col>
          <el-col :span="5">{{ $t("productManagement.size") }}</el-col>
          <el-col :span="5">{{ $t("productManagement.initial") }}</el-col>
          <el-col :span="5">{{ $t("productManagement.quantity") }}</el-col>
          <el-col :span="4">{{ $t("productManagement.price") }}($)</el-col>
        </el-row>
        <!-- 批量填写 -->
        <el-form
          v-if="specData.length > 0"
          :model="fillinForm"
          :rules="fillinRule"
          ref="fillinForm"
          label-width="0"
        >
          <el-row class="fill-in-batch">
            <el-col :span="5">
              <el-form-item prop="colour">
                <el-select
                  v-model="fillinForm.colour"
                  :placeholder="
                    $t('resident.pleaseSelect') + $t('productManagement.colour')
                  "
                >
                  <el-option
                    v-for="(item, index) in [
                      {
                        color: '',
                        colorRemark: this.$t('productManagement.all'),
                      },
                      ...attributeLists,
                    ]"
                    :key="index"
                    :label="
                      !item.color
                        ? item.colorRemark
                        : !item.colorRemark
                        ? item.color
                        : `${item.color}(${item.colorRemark})`
                    "
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="size">
                <el-select
                  v-model="fillinForm.size"
                  :placeholder="
                    $t('resident.pleaseSelect') + $t('productManagement.size')
                  "
                >
                  <el-option
                    v-for="(item, index) in [
                      this.$t('productManagement.all'),
                      ...sizeDataLists,
                    ]"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="initial">
                <el-select
                  v-model="fillinForm.initial"
                  :placeholder="
                    $t('resident.pleaseSelect') +
                    $t('productManagement.initial')
                  "
                >
                  <el-option
                    v-for="(item, index) in [
                      this.$t('productManagement.all'),
                      ...attributeLists2,
                    ]"
                    :key="index"
                    :label="
                      item.small && item.large && item.delivery
                        ? `${item.small} - ${item.large}`
                        : item
                    "
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="quantity">
                <el-input
                  type="number"
                  class="w-auto"
                  v-model.number="fillinForm.quantity"
                  :placeholder="
                    $t('resident.pleaseEnter') +
                    $t('productManagement.quantity')
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="price">
                <el-input
                  type="number"
                  class="w-auto"
                  v-model="fillinForm.price"
                  :placeholder="
                    $t('resident.pleaseEnter') + $t('productManagement.price')
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">&nbsp;</el-col>
            <el-col :span="4">
              <el-button
                type="primary"
                icon="el-icon-finished"
                class="button"
                style="margin-bottom: 10px"
                @click="fillAll"
              >{{ $t("productManagement.batchfill") }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <!-- main -->
        <el-row class="main" v-for="(item, index) in specData" :key="index">
          <el-col :span="5" class="col" align="center">{{ item.color }}</el-col>

          <el-col :span="5" class="col" align="center">{{ item.size }}</el-col>

          <el-col :span="5" class="col" align="center">{{ item.initial }}</el-col>

          <el-col :span="5" class="col" align="center">
            <input
              type="number"
              min="0"
              class="el-input__inner col-input"
              v-model="item.quantity"
              @blur="quantitys(item.quantity)"
              onkeyup="this.value=this.value.replace(/\D/g,'')"
              onafterpaste="this.value=this.value.replace(/\D/g,'')"
            />
          </el-col>
          <el-col :span="4" class="col" align="center">
            <input
              class="col-input el-input__inner"
              type="number"
              min="0"
              v-model="item.price"
              @blur="prices(item.price)"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
            />
            <div class="clear"></div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- bottom -->
    <div class="bottom1">
      <el-button class="button1" @click="cancel">
        {{
        $t("common.cancel")
        }}
      </el-button>
      <!-- <el-button class="button1" @click="nextStep('ruleForm', 2)">{{$t('productManagement.save')}}</el-button> -->
      <el-button class="button2" @click="nextStep(1)">
        {{
        $t("common.nextStep")
        }}
      </el-button>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :title="dialogTitle" :visible="editDialog" @close="editDialog = false" width="35%">
      <el-form label-width="100px" :model="brandForm" :rules="brandRules" ref="brandForm">
        <el-form-item prop="brandName" :label="$t('brand.name')">
          <el-input class="brand_input" v-model="brandForm.brandName"></el-input>
        </el-form-item>
        <el-form-item prop="logo" :label="$t('brand.logo')">
          <img
            src="@/assets/default_brand_logo.png"
            v-if="this.brandForm.logoUrl == ''"
            style="width: 60px; height: 60px"
            class="fl ml"
          />
          <el-image
            v-else
            :src="this.brandForm.logoUrl + '?x-oss-process=style/w128'"
            style="width: 60px; height: 60px;zd"
          ></el-image>
        </el-form-item>
        <MaterialSelector
          :count="1"
          type="image"
          style="margin-left: 50px"
          @receiver="handleSelectionResults($event, 1)"
        ></MaterialSelector>
        <!-- <el-form-item prop="logo" :label="$t('brand.logo')">
          <input
            type="file"
            ref="upload"
            style="display: none"
            accept="image/gif,image/jpeg,image/png,image/jpg"
            @change="freshImg"
            class="brand_logo"
          />

          <img
            src="@/assets/default_brand_logo.png"
            v-if="logoUrl == ''"
            @click="uploadImg"
            class="fl ml brand_logo"
          />
          <img
            :src="logoUrl"
            v-if="logoUrl != ''"
            @click="uploadImg"
            class="fl ml avatar"
          />
        </el-form-item>-->
      </el-form>

      <div class="dialog-footer">
        <el-button @click="editDialog = false">
          {{
          $t("common.close")
          }}
        </el-button>
        <el-button type="primary" @click="saveBrand">
          {{
          $t("common.done")
          }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLists,
  renderData,
  getData,
  createData,
  getDatas
} from "../../helper/api";
import VueTagsInput from "@johmun/vue-tags-input";
const moment = require("moment");
import { getFileNameMd5, put } from "@/helper/ali-oss";
import MaterialSelector from "@/components/MaterialSelector";

export default {
  components: {
    VueTagsInput,
    MaterialSelector
  },
  data() {
    // 体积
    // var volume = (rule, value, callback) => {
    //   const reg = /^([0-9]{1,3})+(.[0-9]{2})?$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error(this.$t("productManagement.volumeRule")));
    //   }
    // };
    // 重量
    var weight = (rule, value, callback) => {
      const reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("productManagement.weightRule")));
      }
    };
    // 正整数验证
    var validatePositiveInteger = (rule, value, callback) => {
      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(
          new Error(this.$t("productManagement.validatePositiveInteger"))
        );
      }
    };
    // 倍数验证
    var isMultipleOf = (rule, value, callback) => {
      const moq = Number(value);
      const boq = Number(this.ruleForm.incMinimum);
      if (moq % boq === 0 && moq >= boq) {
        callback();
      } else {
        callback(new Error(this.$t("productManagement.isMultipleOf")));
      }
    };
    // 因数验证
    var isSubmultipleOf = (rule, value, callback) => {
      const moq = Number(this.ruleForm.minimum);
      const boq = Number(value);
      if (moq % boq === 0 && moq >= boq) {
        callback();
      } else {
        callback(new Error(this.$t("productManagement.isMultipleOf")));
      }
    };
    return {
      // 热门国家，全部国家
      country: [
        {
          label: this.$t("categorys.PopularCountries"),
          options: []
        },
        {
          label: this.$t("categorys.AllCountries"),
          options: []
        }
      ],
      fillinForm: {
        colour: "",
        size: "",
        initial: "",
        quantity: 0,
        price: 0
      },
      fillinRule: {
        colour: [
          {
            required: true,
            message:
              this.$t("resident.pleaseSelect") +
              this.$t("productManagement.colour"),
            trigger: "change"
          }
        ],
        size: [
          {
            required: true,
            message:
              this.$t("resident.pleaseSelect") +
              this.$t("productManagement.size"),
            trigger: "change"
          }
        ],
        initial: [
          {
            required: true,
            message:
              this.$t("resident.pleaseSelect") +
              this.$t("productManagement.initial"),
            trigger: "change"
          }
        ],
        quantity: [
          {
            required: true,
            min: 1,
            type: "number",
            message:
              this.$t("resident.pleaseEnter") +
              this.$t("productManagement.quantity"),
            trigger: "blur"
          }
        ],
        price: [
          {
            required: true,
            min: 0.01,
            message:
              this.$t("resident.pleaseEnter") +
              this.$t("productManagement.price"),
            trigger: "blur"
          }
        ]
      },
      productId: "",
      color: "",
      categories: [],
      category: "",
      brands: [],
      size: "",
      attributeLists: [], //颜色
      attributeLists1: [], //属性
      attributeLists2: [], //初始批量
      attributeListsData: [],
      sizeLists: "",
      colorLists: "",
      stockLists: "",
      sizeDataLists: [],
      sizeData: [],
      myVisible: false,
      sizeEditIndex: "",
      specData: [],
      quantity: {},
      price: {},
      firstCategory: "",
      secondCategory: "",
      secondCategoryData: [],
      thirdCategory: "",
      thirdCategoryData: [],
      dialogTitle: "",
      editDialog: false,
      froms: {},
      logoUrl: "",
      tag: "", // 当前编辑的标签
      //color
      form: {
        attribute: [
          {
            color: this.$t("productManagement.default"),
            colorRemark: "",
            displayShow: ""
          }
        ],
        sizeEditValue: ""
      },
      //起量
      form1: {
        attribute: [
          {
            small: "1",
            large: "",
            displayShow: "",
            delivery: ""
          }
        ],
        sizeEditValue: ""
      },
      //属性
      forms: {
        attribute: [
          {
            key: "",
            value: "",
            displayShow: ""
          }
        ],
        sizeEditValue: ""
      },
      brandForm: {
        brandName: "",
        logoUrl: ""
      },
      brandRules: {
        brandName: [
          {
            required: true,
            message: this.$t("brand.nameRequired"),
            trigger: "blur"
          }
        ]
      },
      ruleForm: {
        trade: "",
        category: [],
        brand: "",
        minimum: 1,
        incMinimum: 1,
        volumeL: "",
        volumeW: "",
        volumeH: "",
        volume: "CM",
        weight: { size: 0, unit: "KG" },
        description: "",
        shipment: "",
        packaging: "",
        graphic: "",
        logo: "",
        video: "",
        tags: []
      },
      rule: {
        trade: [
          {
            required: true,
            message: this.$t("productManagement.tradeRequired"),
            trigger: "blur"
          }
        ],
        category: [
          {
            required: true,
            message: this.$t("productManagement.categoryRequired"),
            trigger: "change"
          }
        ],
        shipment: [
          {
            message: this.$t("productManagement.placeRequired"),
            trigger: "blur"
          }
        ],
        volume: [
          {
            required: true,
            message: this.$t("productManagement.volumeRequired"),
            trigger: "blur"
          }
          // { validator: volume, trigger: "blur" },
        ],
        // volumeL: [
        //   {
        //     required: true,
        //     message: this.$t("productManagement.volumeRequired"),
        //     trigger: "blur",
        //   },
        // ],
        // volumeW: [
        //   {
        //     required: true,
        //     message: this.$t("productManagement.volumeRequired"),
        //     trigger: "blur",
        //   },
        // ],
        // volumeH: [
        //   {
        //     required: true,
        //     message: this.$t("productManagement.volumeRequired"),
        //     trigger: "blur",
        //   },
        // ],
        "weight.size": [
          {
            required: true,
            message: this.$t("productManagement.weightRequired"),
            trigger: "blur"
          },
          { validator: weight, trigger: "blur" }
        ],
        description: [
          {
            required: true,
            message: this.$t("productManagement.descriptionRequired"),
            trigger: "blur"
          }
        ],
        minimum: [
          {
            required: true,
            message: this.$t("productManagement.minimumRequired"),
            trigger: "blur"
          },
          { validator: isMultipleOf, trigger: "blur" },
          { validator: validatePositiveInteger, trigger: "blur" }
        ],
        incMinimum: [
          {
            required: true,
            message: this.$t("productManagement.incMinimumRequired"),
            trigger: "blur"
          },
          { validator: isSubmultipleOf, trigger: "blur" },
          { validator: validatePositiveInteger, trigger: "blur" }
        ]
      },
      option: [
        // {
        //   value: "IN",
        //   label: this.$t("productManagement.in"),
        // },
        // {
        //   value: "FT",
        //   label: this.$t("productManagement.ft"),
        // },
        {
          value: "CM",
          label: this.$t("productManagement.cm")
        }
        // {
        //   value: "MM",
        //   label: this.$t("productManagement.mm"),
        // },
        // {
        //   value: "M",
        //   label: this.$t("productManagement.m"),
        // },
      ],
      options: [
        // {
        //   value: "LB",
        //   label: this.$t("productManagement.lb"),
        // },
        // {
        //   value: "OZ",
        //   label: this.$t("productManagement.oz"),
        // },
        {
          value: "KG",
          label: this.$t("productManagement.kg")
        }
        // {
        //   value: "GR",
        //   label: this.$t("productManagement.gr"),
        // },
      ]
    };
  },
  computed: {
    flagClassObject: function() {
      const selectCountry =
        this.country[0].options.find(v => v.value === this.ruleForm.shipment) ||
        this.country[1].options.find(v => v.value === this.ruleForm.shipment);
      if (selectCountry) {
        return {
          ["flag-" + selectCountry.iso_code]:
            !!this.ruleForm.shipment && !!selectCountry
        };
      } else {
        return null;
      }
    }
  },
  created() {
    this.productId = this.$route.query.product_id
      ? this.$route.query.product_id
      : "";

    this.getStore();
    this.getBrand();
    if (this.productId) {
      this.getProduct(this.productId);
    }

    this.form.attribute.forEach(item => {
      if (item.color != "") {
        this.attributeLists.push(item);
      }
    });
    this.getCountry();
  },
  methods: {
    handleSelectionResult(result, index) {
      console.log(
        "handleSelectionResult:",
        result,
        index,
        this.form.attribute[index]
      );
      this.$set(this.form.attribute[index], "img", result.filename);
    },
    handleSelectionResults(result, type) {
      console.log("result, type:", result, type);
      if (type === 1) {
        // 主图
        this.brandForm.logoUrl = result.filename;
        // this.gallery = [...gallery];
        // console.log("this.ruleForm.logo:", this.ruleForm.logo);
      }
    },
    handleDeleteColorImage(result, index) {
      this.$set(this.form.attribute[index], "img", "");
    },
    async handleUpload(option) {
      // 生成的文件名称
      const fileName = await getFileNameMd5(option.file);

      // 调用 ali-oss 中的方法
      put(fileName, option.file)
        .then(res => {
          console.log(res);
          this.ruleForm.video = res.url;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCountry() {
      getDatas("GET", "country").then(res => {
        console.log(res);
        const allCountry = res.data.map(v => {
          return {
            value: v.name,
            iso_code: v.iso_code.toLowerCase(),
            label: v.name
          };
        });
        const popularCountry = ["China", "India", "France"];
        this.country[1].options = allCountry;
        this.country[0].options = allCountry.filter(
          v => popularCountry.indexOf(v.value) > -1
        );
      });
    },
    onUploadSuccess(url) {
      console.log("onUploadSuccess:", url);
      this.ruleForm.video = url;
    },
    fillAll() {
      this.$refs["fillinForm"].validate(valid => {
        if (valid) {
          const { colour, size, initial, quantity, price } = this.fillinForm;
          const colourObj = this.attributeLists[colour - 1];
          const colourStr = colour > 0 && colourObj.color;
          const sizeStr = this.sizeDataLists[size - 1] + "";
          const initialObj = this.attributeLists2[initial - 1];
          const initialStr =
            initial > 0 && `${initialObj.small}~${initialObj.large}`;
          console.log(colour, size, initial, quantity, price);
          const newSpecData = this.specData.map(v => {
            if (
              (colour === 0 || v.color === colourStr) &&
              (size === 0 || v.size === sizeStr) &&
              (initial === 0 || v.initial === initialStr)
            ) {
              return {
                ...v,
                price: price + "",
                quantity: quantity + ""
              };
            } else {
              return v;
            }
          });
          this.specData = newSpecData;
        }
      });
    },
    addBrand() {
      this.editDialog = true;
      this.dialogTitle = this.$t("common.add");
      this.brandForm.brandName = "";
      this.brandForm.logoUrl = "";
    },
    saveBrand() {
      this.$refs["brandForm"].validate(valid => {
        if (valid) {
          createData("POST", "brand", {
            img: this.brandForm.logoUrl,
            brandName: this.brandForm.brandName
          }).then(res => {
            if (res.success == true) {
              this.getBrand();
              this.ruleForm.brand = res.data._id;
              this.$message({
                message: res.success
                  ? this.$t("brand.brandCreateSuccess")
                  : this.$t("brand." + res.message),
                type: res.success ? "success" : "error"
              });
              this.editDialog = false;
            } else {
              if (
                res.message ==
                '"brandName" length must be at least 2 characters long'
              ) {
                this.$message({
                  message: this.$t("productManagement.brandName"),
                  type: "error"
                });
              } else if (res.message == "This Brand is already exist.") {
                this.$message({
                  message: this.$t("productManagement.Brand"),
                  type: "error"
                });
              }
            }
          });
        }
      });
    },
    uploadImg() {
      this.$refs["upload"].click();
    },
    freshImg(e) {
      let _this = this;
      _this.imgObj = e.target.files["0"];
      let fr = new FileReader();
      fr.onload = function() {
        _this.logoUrl = fr.result;
        let logo = _this.logoUrl.replace(/^data:image\/\w+;base64,/, "");

        createData("POST", "image/common", {
          dir: "brand_images",
          name:
            moment(new Date()).format("YYYYMMDDHHmmss") +
            Math.round(Math.random() * 99999),
          image: logo
        }).then(res => {
          _this.brandForm.logo = res.data.image;
        });
      };
      fr.readAsDataURL(_this.imgObj); //将读取到的文件编码成Data URL
    },
    getStore() {
      renderData("GET", "store").then(res => {
        this.firstCategory = res.data.mainCategoryId;
        this.getCategory();
      });
    },
    getProduct(id) {
      let _this = this;

      // console.log(_this.secondCategoryData)
      getData("GET", "product", id).then(res => {
        _this.ruleForm.volume = "CM";
        _this.ruleForm.trade = res.data.productName;
        _this.ruleForm.video = res.data.video;
        _this.ruleForm.brand = res.data.brandId;
        _this.ruleForm.price = res.data.price;
        _this.ruleForm.salePrice = res.data.salePrice;
        _this.ruleForm.shipment = res.data.shipment;
        _this.ruleForm.minimum = res.data.minimum;
        _this.ruleForm.incMinimum = res.data.incMinimum;
        const tempvolume = res.data.volume.size.split("*");
        if (tempvolume && tempvolume.length && tempvolume.length === 3) {
          _this.ruleForm.volumeL = tempvolume[0] * 100;
          _this.ruleForm.volumeW = tempvolume[1] * 100;
          _this.ruleForm.volumeH = tempvolume[2] * 100;
        }
        _this.ruleForm.weight = {...res.data.weight, unit: "KG"};
        _this.ruleForm.category = res.data.categoryId;

        if (res.data.custom) {
          _this.ruleForm.packaging = res.data.custom.packaging;
          _this.ruleForm.graphic = res.data.custom.graphic;
          _this.ruleForm.logo = res.data.custom.logo;
        }

        _this.forms.attribute = res.data.attributes;
        _this.attributeLists1 = _this.forms.attribute;

        if (res.data.attributes.length <= 0) {
          _this.forms.attribute.push({
            key: "",
            value: "",
            displayShow: ""
          });
        }

        _this.ruleForm.description = res.data.description;
        _this.ruleForm.tags = res.data.tags.split(",").filter(Boolean);

        let colorData = [];
        let sizeData = [];
        let initialData = [];
        let specData = [];
        res.data.selectObj.selects.forEach((item, index) => {
          colorData.push({
            color: item.name,
            img: item.img,
            colorRemark: item.remark ? item.remark : "",
            displayShow: "display_show"
          });
          colorData = [...new Set(colorData)];

          item.specs.forEach((size, i) => {
            sizeData.push(size.name);
            sizeData = [...new Set(sizeData)];

            if (index == 0 && i == 0) {
              size.durPrice.forEach(initial => {
                initialData.push({
                  small: initial.min,
                  large: initial.max,
                  displayShow: "display_show",
                  delivery: initial.time
                });
              });
            }

            size.durPrice.forEach(initial => {
              specData.push({
                color: item.name,
                colorRemark: item.colorRemark,
                size: size.name,
                initial: initial.min + "~" + initial.max,
                quantity: initial.stock,
                price: initial.price,
                time: initial.time
              });
              specData = [...new Set(specData)];
            });
          });
        });
        colorData.forEach((item, index) => {
          if (index == colorData.length - 1) {
            colorData[index].displayShow = "";
          }
        });
        _this.form.attribute = colorData;
        _this.attributeLists = _this.form.attribute;
        _this.sizeDataLists = sizeData;

        initialData.forEach((item, index) => {
          if (index == initialData.length - 1) {
            initialData[index].displayShow = "";
          }
        });
        _this.form1.attribute = initialData;
        if (initialData.length <= 0) {
          _this.form1.attribute.push({
            small: "",
            large: "",
            displayShow: "",
            delivery: ""
          });
        }
        _this.attributeLists2 = _this.form1.attribute;
        _this.specData = specData;
        console.log("_this.ruleForm:", _this.ruleForm);
      });
    },
    unique(arr) {
      const res = new Map();
      return arr.filter(arr => !res.has(arr.id) && res.set(arr.id, 1));
    },
    getCategory() {
      renderData("GET", "store").then(res => {
        getLists("get", "storeCategory/" + res.data._id).then(re => {
          createData("POST", "category").then(cate => {
            cate.data.forEach(item => {
              re.data.forEach(mc => {
                if (item._id == mc.categoryId) {
                  this.categories.push(item);
                }
              });
            });

            for (var i = 0; i < this.categories.length; i++) {
              if (this.categories[i]._id == this.firstCategory) {
                this.secondCategoryData = this.categories[i].arr;
              }
            }

            this.secondCategoryData.forEach(item => {
              if (item._id == this.ruleForm.category) {
                this.secondCategory = item._id;
              } else {
                for (var j = 0; j < item.arr.length; j++) {
                  if (this.ruleForm.category == item.arr[j]._id) {
                    this.thirdCategory = item.arr[j]._id;
                    this.thirdCategoryData = item.arr;
                    this.secondCategory = item.arr[j].parent;
                  }
                }
              }
            });
          });
        });
      });
    },
    getCategoryParent() {
      renderData("GET", "category", this.ruleForm.categoryId).then(res => {
        this.secondCategory = res.data.parent;
      });
    },
    changeFirstCategory() {
      this.secondCategoryData = [];
      this.secondCategory = "";
      this.thirdCategoryData = [];
      this.thirdCategory = "";

      this.categories.forEach(item => {
        if (item._id == this.firstCategory) {
          this.secondCategoryData = item.arr;
        }
      });
    },
    changeSecondCategory() {
      this.thirdCategoryData = [];
      this.thirdCategory = "";
      this.secondCategoryData.forEach(item => {
        if (item._id == this.secondCategory) {
          this.thirdCategoryData = item.arr;
        }
      });

      if (this.thirdCategoryData.length <= 0) {
        this.ruleForm.category = this.secondCategory;
      }
    },
    chagneThirdCategory() {
      this.ruleForm.category = this.thirdCategory;
    },
    getBrand() {
      getLists("get", "brand").then(res => {
        this.brands = res.data.rows;
      });
    },
    getSpecData() {
      const newSpecData = [];
      this.attributeLists.forEach(item => {
        for (var i = 0; i < this.sizeDataLists.length; i++) {
          for (var j = 0; j < this.attributeLists2.length; j++) {
            const push = {
              color: item.color,
              colorRemark: item.colorRemark,
              size: this.sizeDataLists[i],
              initial:
                this.attributeLists2[j].small +
                "~" +
                this.attributeLists2[j].large,
              quantity: "",
              price: "",
              time: this.attributeLists2[j].delivery
            };
            const older = this.specData.find(
              old =>
                old.color === push.color &&
                old.size === push.size &&
                old.initial === push.initial
            );
            if (older) {
              push.quantity = older.quantity;
              push.price = older.price;
            }
            newSpecData.push(push);
          }
        }
      });
      this.specData = newSpecData;
    },
    searchs() {
      this.search1 = true;
    },
    searchss() {
      this.search2 = true;
    },
    volumeLs(i) {
      if (i == "") {
        this.$message({
          message: this.$t("productManagement.volumeRequired"),
          type: "error"
        });
      }
    },
    volumeWs(i) {
      if (i == "") {
        this.$message({
          message: this.$t("productManagement.volumeRequired"),
          type: "error"
        });
      }
    },
    volumeHs(i) {
      if (i == "") {
        this.$message({
          message: this.$t("productManagement.volumeRequired"),
          type: "error"
        });
      }
    },
    // 数量
    quantitys(i) {
      if (i == "") {
        // alert(this.$t("productManagement.quantitys"))
        this.$message({
          message: this.$t("productManagement.quantitys"),
          type: "error"
        });
      }
    },
    // 价格
    prices(i) {
      if (i == "") {
        // alert(this.$t("productManagement.prices"))
        this.$message({
          message: this.$t("productManagement.prices"),
          type: "error"
        });
      }
    },
    // 属性输入框
    enterAttributes(index) {
      this.attributeLists1 = [];

      for (var i = 0; i < this.forms.attribute.length; i++) {
        if (
          this.forms.attribute[index].key == this.forms.attribute[i].key &&
          index != i &&
          this.forms.attribute[i].key != ""
        ) {
          this.$message({
            message: this.$t("productManagement.attributeRepeat"),
            type: "error"
          });
          this.forms.attribute[index].key = "";
          return;
        }
      }

      this.forms.attribute.forEach(item => {
        if (item.key != "") this.attributeLists1.push(item);
      });
      //this.getSpecData();

      for (var j = 0; j < this.forms.attribute.length; j++) {
        if (this.forms.attribute[j].key == "") return;
      }

      this.forms.attribute.push({
        key: "",
        value: "",
        displayShow: ""
      });
    },
    // 颜色
    enterAttribute(index, type) {
      this.attributeLists = [];

      this.form.attribute.forEach((item, i) => {
        if (index != i && item.color == this.form.attribute[index].color) {
          this.$message({
            message: this.$t("productManagement.colorRepeated"),
            type: "error"
          });
          this.form.attribute[index].color = "";
          return;
        }

        if (item.color != "") this.attributeLists.push(item);
      });
      if (type == 0) this.getSpecData();

      for (var j = 0; j < this.form.attribute.length; j++) {
        if (this.form.attribute[j].color == "") return;
      }
      this.form.attribute[index]["displayShow"] = "display_show";
      this.form.attribute.push({
        color: "",
        colorRemark: "",
        displayShow: ""
      });
    },
    // 颜色
    attrOperate(type, index) {
      this.attributeLists = [];
      if (type == 1) {
        if (this.form.attribute[index]["color"] == "") {
          this.$message({
            message: this.$t("productManagement.attributeRequired"),
            type: "error"
          });
          return;
        }
        this.form.attribute[index]["displayShow"] = "display_show";
        this.form.attribute.push({
          color: "",
          colorRemark: "",
          displayShow: "",
          initial: ""
        });

        this.form.attribute.forEach(item => {
          if (item.color != "") this.attributeLists.push(item);
        });

        this.getSpecData();
      } else {
        let attrData = [];

        if (this.form.attribute.length <= 1) {
          this.$message({
            message: this.$t("productManagement.attributeOne"),
            type: "error"
          });
          return;
        }

        for (var i = 0; i < this.form.attribute.length; i++) {
          if (i != index) {
            attrData.push(this.form.attribute[i]);
          }
        }
        this.form.attribute = attrData;
        this.form.attribute[this.form.attribute.length - 1]["displayShow"] = "";
        this.form.attribute.forEach(item => {
          if (item.color != "") this.attributeLists.push(item);
        });

        this.getSpecData();
      }
    },
    // 初始批量
    enterAttribute2(index) {
      this.attributeLists2 = [];

      if (parseFloat(this.form1.attribute[index].small) <= 0) {
        this.$message({
          message: this.$t("productManagement.initialThanZero"),
          type: "error"
        });
        return;
      }
      if (
        parseFloat(this.form1.attribute[index].large) <=
        parseFloat(this.form1.attribute[index].small)
      ) {
        this.$message({
          message:
            this.$t("productManagement.initialLarge") +
            parseFloat(this.form1.attribute[index].small),
          type: "error"
        });
        return;
      }

      for (var i = 0; i < this.form1.attribute.length; i++) {
        if (this.form1.attribute[i].small != "") {
          if (i > 0) {
            this.form1.attribute[i].small =
              parseFloat(this.form1.attribute[i - 1].large) + 1;
          }

          if (
            parseFloat(this.form1.attribute[i].small) >=
            parseFloat(this.form1.attribute[i].large)
          ) {
            this.form1.attribute[i].large =
              parseFloat(this.form1.attribute[i].small) + 1;
          }
        }

        if (
          this.form1.attribute[i].small != "" &&
          this.form1.attribute[i].large != "" &&
          this.form1.attribute[i].delivery != ""
        ) {
          this.attributeLists2.push(this.form1.attribute[i]);
        }
      }
      this.getSpecData();

      for (var j = 0; j < this.form1.attribute.length; j++) {
        if (
          this.form1.attribute[j].small == "" ||
          this.form1.attribute[j].large == "" ||
          this.form1.attribute[j].delivery == ""
        )
          return;
      }
      this.form1.attribute[index]["displayShow"] = "display_show";

      this.form1.attribute.push({
        small: parseFloat(this.form1.attribute[index].large) + 1,
        large: "",
        displayShow: "",
        delivery: ""
      });
    },
    // 初始批量增减
    attrOperate2(type, index) {
      this.attributeLists2 = [];
      if (type == 1) {
        if (
          this.form1.attribute[index].small == "" ||
          this.form1.attribute[index].large == ""
        ) {
          this.$message({
            message: this.$t("productManagement.startBatch"),
            type: "error"
          });
          return;
        }
        if (this.form1.attribute[index].delivery == "") {
          this.$message({
            message: this.$t("productManagement.delivery"),
            type: "error"
          });
          return;
        }

        this.form1.attribute[index]["displayShow"] = "display_show";
        this.form1.attribute.push({
          small: parseFloat(this.form1.attribute[index].large) + 1,
          large: "",
          displayShow: "",
          delivery: ""
        });

        this.form1.attribute.forEach(item => {
          if (
            item.small != "" &&
            item.large != "" &&
            item.delivery != "" &&
            item.large > item.small
          )
            this.attributeLists2.push(item);
        });

        this.getSpecData();
      } else {
        let attrData = [];

        if (this.form1.attribute.length <= 1) {
          this.$message({
            message: this.$t("productManagement.attributeOne"),
            type: "error"
          });
          return;
        }
        for (var i = 0; i < this.form1.attribute.length; i++) {
          if (i != index) {
            attrData.push(this.form1.attribute[i]);
          }
        }
        this.form1.attribute = attrData;
        this.form1.attribute[this.form1.attribute.length - 1]["displayShow"] =
          "";
        this.form1.attribute.forEach(item => {
          if (item.small != "" && item.large != "" && item.large > item.small)
            this.attributeLists2.push(item);
        });
        this.getSpecData();
      }
    },
    // 属性加减
    attrOperates(type, index) {
      this.attributeLists1 = [];
      if (type == 1) {
        if (this.forms.attribute[index]["key"] == "") {
          this.$message({
            message: this.$t("productManagement.attributeRequired"),
            type: "error"
          });
          return;
        }

        this.forms.attribute[index]["displayShow"] = "display_show";
        this.forms.attribute.push({
          key: "",
          value: "",
          displayShow: ""
        });

        this.forms.attribute.forEach(item => {
          if (item.key != "") this.attributeLists1.push(item);
        });

        // this.getSpecData();
      } else {
        let attrData = [];

        if (this.forms.attribute.length <= 1) {
          this.$message({
            message: this.$t("productManagement.attributeOne"),
            type: "error"
          });
          return;
        }

        for (var i = 0; i < this.forms.attribute.length; i++) {
          if (i != index) {
            attrData.push(this.forms.attribute[i]);
          }
        }
        this.forms.attribute = attrData;
        this.forms.attribute[this.forms.attribute.length - 1]["displayShow"] =
          "";
        this.forms.attribute.forEach(item => {
          if (item.key != "") this.attributeLists1.push(item);
        });

        // this.getSpecData();
      }
    },
    // 弹框修改
    doneForm() {
      if (this.form.sizeEditValue == "") {
        this.$message({
          message: this.$t("productManagement.sizeInput"),
          type: "error"
        });
        return;
      }

      this.myVisible = false;

      this.sizeDataLists.forEach((item, index) => {
        if (this.form.sizeEditValue == item && index != this.sizeEditIndex) {
          this.$message({
            message: this.$t("productManagement.sizeRepeated"),
            type: "error"
          });
        }

        if (this.form.sizeEditValue != item && index == 0) {
          this.sizeDataLists[this.sizeEditIndex] = this.form.sizeEditValue;
        }
      });
      this.sizeEditIndex = "";
      this.getSpecData();
    },
    // 添加
    add() {
      this.sizeDataLists.forEach(item => {
        if (this.size == item) {
          this.$message({
            message: this.$t("productManagement.sizeRepeated"),
            type: "error"
          });

          this.size = "";
        }
      });

      if (this.size !== "") this.sizeDataLists.push(this.size);
      this.size = "";
      this.getSpecData();
    },
    // 编辑
    edit(index) {
      this.myVisible = true;
      this.sizeEditIndex = index;
      this.form.sizeEditValue = this.sizeDataLists[index];
    },
    // 关闭
    close() {
      this.myVisible = false;
    },
    // 隐藏
    hide() {
      this.myVisible = false;
    },
    // 删除
    remove(index) {
      this.sizeDataLists.splice(index, 1);
      this.getSpecData();
    },

    // 取消
    cancel() {
      this.$router.push("/commodity/products");
    },
    // 下一步
    nextStep(type) {
      console.log("this.ruleForm:", this.ruleForm);
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (
            this.attributeLists.length <= 0 ||
            this.attributeLists[0].color == ""
          ) {
            this.$message({
              message: this.$t("productManagement.attributeOne"),
              type: "error"
            });
            return;
          }
          if (this.sizeDataLists.length <= 0 || this.sizeDataLists[0] == "") {
            this.$message({
              message: this.$t("productManagement.sizeInput"),
              type: "error"
            });
            return;
          }

          if (
            this.attributeLists2.length <= 0 ||
            parseFloat(this.attributeLists2[0].min) <= 0 ||
            parseFloat(this.attributeLists2[0].large <= 0) ||
            parseFloat(this.attributeLists2[0].delivery) <= 0
          ) {
            this.$message({
              message: this.$t("productManagement.startBatch"),
              type: "error"
            });
            return;
          }
          if (
            this.ruleForm.volumeL == "" ||
            this.ruleForm.volumeW == "" ||
            this.ruleForm.volumeH == ""
          ) {
            this.$message({
              message: this.$t("productManagement.volumeRequired"),
              type: "error"
            });
            return;
          }

          // if (this.ruleForm.volumeW == "") {
          //   this.$message({
          //     message: this.$t("productManagement.volumeRequired"),
          //     type: "error",
          //   });
          // }
          // if (this.ruleForm.volumeH == "") {
          //   this.$message({
          //     message: this.$t("productManagement.volumeRequired"),
          //     type: "error",
          //   });
          // }

          let colorDataLists = [];
          this.attributeLists.forEach(color => {
            let sizeData = [];
            this.sizeDataLists.forEach(size => {
              let data = [];
              this.attributeLists2.forEach(attr => {
                let spec = {};
                this.specData.forEach(item => {
                  let initial = item.initial.split("~");
                  if (
                    item.color == color.color &&
                    item.size == size &&
                    attr.small == initial[0] &&
                    attr.large == initial[1]
                  ) {
                    spec = {
                      min: initial[0],
                      max: initial[1],
                      time: attr.delivery,
                      price: item.price,
                      stock: item.quantity
                    };

                    data.push(spec);
                    return false;
                  }
                });
              });

              sizeData.push({
                name: size,
                durPrice: data
              });
            });

            colorDataLists.push({
              name: color.color,
              remark: color.colorRemark,
              title: "size",
              specs: sizeData,
              img: color.img
            });
          });

          let attributeLists1 = [];
          this.attributeLists1.forEach(item => {
            attributeLists1.push({
              key: item.key,
              value: item.value
            });
          });

          const data = {
            video: this.ruleForm.video,
            productName: this.ruleForm.trade,
            brandId: this.ruleForm.brand,
            categoryId: this.ruleForm.category,
            categoryIds: [
              this.firstCategory,
              this.secondCategory,
              this.ruleForm.category
            ],
            shipment: this.ruleForm.shipment,
            minimum: this.ruleForm.minimum,
            incMinimum: this.ruleForm.incMinimum,
            volume: {
              size: `${this.ruleForm.volumeL / 100}*${this.ruleForm.volumeW / 100}*${this.ruleForm.volumeH / 100}`,
              unit: "M"
            },
            weight: this.ruleForm.weight,
            custom: {
              packaging: +this.ruleForm.packaging,
              graphic: +this.ruleForm.graphic,
              logo: +this.ruleForm.logo
            },
            attributes: attributeLists1,
            description: this.ruleForm.description,
            tags: this.ruleForm.tags.join(","),
            selectObj: {
              title: "color",
              selects: colorDataLists
            }
          };
          let MOD = "POST";
          if (this.productId) {
            data.id = this.productId;
            MOD = "PUT";
          }
          createData(MOD, "product", data).then(res => {
            if (res.success == true) {
              if (type == 1)
                this.$router.push("releaseimg?product_id=" + res.data._id);
              else {
                this.$message({
                  message: res.message,
                  type: "success"
                });
              }
            } else {
              if (res.message == '"price" must be a number') {
                this.$message({
                  message: this.$t("productManagement.priceRequestNumber"),
                  type: "error"
                });
              } else if (res.message == '"stock" must be a number') {
                this.$message({
                  message: this.$t("productManagement.stockRequest"),
                  type: "error"
                });
              } else if (
                res.message == '"productName" is not allowed to be empty'
              ) {
                this.$message({
                  message: this.$t("productManagement.productName"),
                  type: "error"
                });
              } else if (
                res.message ==
                '"productName" length must be at least 3 characters long'
              ) {
                this.$message({
                  message: this.$t("productManagement.productNameLength"),
                  type: "error"
                });
              } else if (
                res.message == '"categoryId" is not allowed to be empty'
              ) {
                this.$message({
                  message: this.$t("productManagement.categoryId"),
                  type: "error"
                });
              } else if (
                res.message == '"description" is not allowed to be empty'
              ) {
                this.$message({
                  message: this.$t("productManagement.descriptions"),
                  type: "error"
                });
              } else if (
                res.message ==
                '"description" length must be at least 3 characters long'
              ) {
                this.$message({
                  message: this.$t("productManagement.descriptionsLength"),
                  type: "error"
                });
              } else if (
                res.message ==
                '"description" length must be at least 3 characters long'
              ) {
                this.$message({
                  message: this.$t("productManagement.descriptionsLength"),
                  type: "error"
                });
              } else if (res.message == '"size" must be a number') {
                this.$message({
                  message: this.$t("productManagement.sizes"),
                  type: "error"
                });
              } else if (res.message == '"minimum" is required') {
                this.$message({
                  message: this.$t("productManagement.minimum"),
                  type: "error"
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "error"
                });
              }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
@import url("../../style/flags.css");
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.add {
  position: relative;
  width: 38px;
  height: 40px;
  border: 1px solid #c6c6c6;
  margin-left: 5px;
  background: #999999;
}
.add img {
  position: absolute;
  left: 7px;
  top: 8px;
}

.right {
  width: 310px;
  height: 897px;
  border: 1px solid #ffdcbc;
  border-radius: 20px;
  background-color: #fff;
  margin-right: 20px;
  float: right;
}
.right .heads {
  height: 60px;
  border: 1px solid #ffdcbc;
  background: #ffdcbc;
  border-radius: 20px 20px 0 0;
}
.right .heads p {
  font-size: 15px;
  font-weight: 500;
  padding-top: 17px;
  margin-left: 25px;
}
.right .bottom .img {
  width: 222px;
  height: 676px;
  margin-top: 39px;
  margin-left: 44px;
  background: #fafafa;
  border-radius: 10px;
}
.right .bottom .img .img1 {
  margin-top: 313px;
  margin-left: 86px;
}
.right .bottom .button {
  width: 223px;
  height: 53px;
  background: #c4c4c4;
  color: #fff;
  font-size: 13px;
  border-radius: 10px;
  margin-top: 34px;
  margin-left: 43px;
}
.attribute .checkbox {
  width: 19px;
}
.attribute h2 {
  font-size: 16px;
}
.attribute input {
  width: 19px;
  height: 21px;
  margin-right: 10px;
}
.attribute .input2 {
  width: 330px;
  height: 40px;
  margin-left: 40px;
}
.attribute .input3 {
  width: 330px;
  height: 40px;
  margin-left: 40px;
}
.attribute .select2 {
  margin-top: 17px;
}
.attribute .select3 {
  width: 330px;
  height: 40px;
}
.attribute .select4 {
  width: 330px;
  height: 40px;
}
.attribute .small {
  width: 118px;
}
.attribute .large {
  width: 118px;
}
.attribute .delivery {
  position: relative;
  width: 330px;
  height: 40px;
  margin-top: -10px;
  margin-left: 5px;
  margin-right: 30px;
}
.attr_operate {
  width: 40px;
  height: 30px;
  border: 1px red solid;
  border-radius: 50%;
  display: inline-table;
  font-size: 28px;
  text-align: center;
  margin-left: 10px;
  background-color: red;
  color: #ffffff;
  cursor: pointer;
}
.start {
  font-size: 13px;
}
.sizebox {
  margin-top: 30px;
}
.size {
  background-color: #ffffff;
  height: 41px;
  margin-bottom: 10px;
}
.size span {
  font-size: 13px;
  margin-right: 10px;
}
.size .input1 {
  width: 200px;
  height: 41px;
}
.size .button {
  width: 88px;
  height: 41px;
  font-size: 13px;
  margin-left: 10px;
}
.card {
  border-radius: 10px;
  margin-top: 20px;
}
.el-icon-edit,
.el-icon-delete {
  cursor: pointer;
}
.card .heads {
  height: 25px;
  border-bottom: 1px solid #e0e0e0;
}
.edit {
  margin-right: 5px;
}
.specifications {
  margin-top: 25px;
}
.specifications h2 {
  font-size: 16px;
}
.specifications .card {
  margin-top: 25px;
}
.specifications .title {
  background: #ffdcbc;
  height: 77px;
  line-height: 77px;
  text-align: center;
}
.specifications .main {
  text-align: center;
  border-top: 1px solid #ffdcbc;
  border-bottom: none;
  padding-bottom: 10px;
}
.specifications .main .col {
  padding-top: 20px;
}
.specifications .el-input__inner {
  width: 80%;
  margin-top: -10px;
  height: 40px;
  line-height: 40px;
}
/* .el-col-4 p {
   margin-left: 20px;
} */
.dollar {
  font-size: 18px;
  font-weight: 700;
  margin-left: -5px;
  margin-top: 10px;
  line-height: 40px;
  border-radius: 5px;
}
.bottom1 {
  height: 60px;
  position: sticky;
  bottom: 0;
  margin-top: 20px;
  text-align: center;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
}
.bottom1 .button1 {
  width: 131px;
  height: 100%;
  margin-right: 41px;
  background: #fae1e0;
  color: #ff0a0a;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 0%;
}
.bottom1 .button2 {
  min-width: 131px;
  height: 100%;
  background: #ff0a0a;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 0%;
}
.checkboxs i {
  font-size: 10px;
  margin-left: 3px;
}
.goods-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px 2px 0px #c4c4c4;
  padding: 20px 10px 10px 30px;
}
.goods-form .label {
  width: 180px;
}
.el-form-item {
  width: auto;
}
.el-form-item .el-form-item__label {
  width: 160px !important;
  display: inline;
}
.el-input {
  width: 700px;
}

.display_show {
  display: none !important;
}
.editSizeValue {
  width: auto;
}
.price .el-input__inner {
  margin-left: 22%;
}
/* .mt10 {
  margin-left: 16%; 
} */
.mt20 {
  margin-top: 20px;
}
.add_brand {
  color: red;
  margin-left: 10px;
  cursor: pointer;
}
.dialog-footer {
  margin-top: 20px;
  text-align: right;
}
.brand_input {
  width: 300px;
}
.brand_logo {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.description .el-form-item__content {
  width: 700px;
  position: relative;
}
.category .el-input__inner {
  width: 233px;
}
.delivery .el-input__inner {
  position: absolute;
  top: 8px;
  left: 10%;
}
.delivery .el-input__suffix {
  position: relative;
  position: absolute;
  top: 8px;
  right: -25px;
}
.attribute .delete {
  position: absolute;
  font-size: 18px;
  top: 10px;
}

.colour {
  margin-left: -150px;
}
.attribute {
  position: relative;
}
.attribute .p {
  width: 732px;
  color: gray;
  font-size: 13px;
}
.volume {
  width: 110px;
}
.volumes {
  width: 185px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.weight {
  width: 110px;
}
.weights {
  width: auto;
}
.line {
  width: 15px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 40px;
  border: 1px solid #000;
}
.delete {
  font-size: 18px;
  margin-left: 31px;
}
.tags .ti-input {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  padding: 7px 0 7px 5px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 700px;
}
.x {
  font-size: 18px;
  margin: 0px 5px 0 5px;
}
div .el-textarea__inner {
  font-family: "微软雅黑";
}

.fill-in-batch {
  padding: 20px;
  padding-bottom: 0;
}
.w-auto {
  width: auto;
}
.video-display {
  width: 360px;
  height: 180px;
}
</style>
